;(function ($) {
  /**
   * -------------------
   * DEFAULT SCRIPTS
   * -------------------
   * Comment out the scripts you are not using. The compiler will get rid of the comments and minify for you.
   */

  $(window).on('load', function () {
    initForm()

    // Components loading animations
    $('.view-animation').viewportChecker({
      classToAdd: 'animated',
      offset: 20
    })

    $(document).on('click', '.inline-video-trigger', function () {
      if ($(this).data('video-id')) {
        if ($(this).hasClass('vimeo')) {
          var iframeHTML =
            '<iframe src="https://player.vimeo.com/video/' +
            $(this).attr('data-video-id') +
            '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
        } else {
          var iframeHTML =
            '<iframe src="https://www.youtube.com/embed/' +
            $(this).attr('data-video-id') +
            '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
        }

        $(this)
          .parent('.video-preview-container')
          .find('.inline-video-trigger')
          .hide()
        $(this)
          .parent('.video-preview-container')
          .addClass('playing')
        $(this)
          .parent('.video-preview-container')
          .find('.overlay')
          .hide()
        $(this)
          .parent('.video-preview-container')
          .find('iframe')
          .remove()
        $(this)
          .parent('.video-preview-container')
          .append(iframeHTML)
      } else {
        console.error('no video ID provided.')
      }
    })

    // get all sliders, we need to loop them due to different settings + nav
    var swipers = document.querySelectorAll(
      '.swiper-container:not(.control):not(.mobile)'
    )
    window.wswipers = []

    swipers.forEach(function (el, index) {
      var closestSection = el.closest('section')
      var controls = closestSection.querySelector('.control')

      var isTestimonials = closestSection.classList.contains('testimonials')
      var isProducts = closestSection.classList.contains('product-slider')
      var isVideos = closestSection.classList.contains('videoslider')

      // slider settings
      var options = {
        speed: 600,
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        navigation: {},
        thumbs: {},
        breakpoints: {
          768: {
            slidesPerView: 2
          },
          1199: {
            slidesPerView: 3
          }
        }
      }

      if (el.hasAttribute('data-slidestoshow')) {
        options.breakpoints = {
          992: {},
          1199: {}
        }
        options.breakpoints[992].slidesPerView = parseInt(
          el.getAttribute('data-slidestoshow')
        )
        options.breakpoints[1199].slidesPerView = parseInt(
          el.getAttribute('data-slidestoshow')
        )
      }

      if (el.hasAttribute('data-spacebetween')) {
        options.spaceBetween = 15
        options.breakpoints[1199].spaceBetween = parseInt(
          el.getAttribute('data-spacebetween')
        )
      }

      if (el.hasAttribute('data-pagination')) {
        options.pagination = {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      }

      if (el.hasAttribute('data-noloop')) {
        options.loop = false
      }

      // init nav
      options.navigation = {
        nextEl: closestSection.querySelector('.swiper-next'),
        prevEl: closestSection.querySelector('.swiper-prev')
      }

      // maybe add gallery controls to the slider
      if (controls) {
        options.thumbs.swiper = new Swiper(controls, {
          speed: 600,
          loop: false,
          slidesPerView: 2,
          spaceBetween: 10,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true
          },
          breakpoints: {
            678: {
              slidesPerView: 3
            }
          }
        })
      }

      if (isTestimonials) {
        options.breakpoints = {
          992: {
            slidesPerView: 2
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 13
          }
        }
      }
      if (isProducts) {
        options.breakpoints = {
          992: {
            slidesPerView: 3
          }
        }
        options.initialSlide = 1
        options.centeredSlides = true
        options.autoplay = false
      }

      if (isVideos) {
        options.slidesPerView = 'auto'
        options.spaceBetween = 50
        options.breakpoints = {
          992: {
            spaceBetween: 40
          }
        }
        options.initialSlide = 1
        options.centeredSlides = true
        options.autoplay = false
        options.loop = false
      }

      // init slider
      if (el.hasAttribute('id')) {
        window.wswipers[el.getAttribute('id')] = new Swiper(el, options)
      } else {
        new Swiper(el, options)
      }
    })

    // mobile sliders, like logo rows etc
    // need to loop in order to get the slide count
    var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
    mobileSwipers.forEach(function (el, index) {
      var closestSection = el.closest('section')
      var slideCount = el.querySelectorAll('.swiper-slide').length

      var options = {
        speed: 600,
        slidesPerView: 2,
        watchOverflow: true,
        loop: true,
        simulateTouch: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          576: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: slideCount
          },
          1280: {
            slidesPerView: slideCount
          }
        }
      }

      // init nav
      options.navigation = {
        nextEl: closestSection.querySelector('.swiper-next'),
        prevEl: closestSection.querySelector('.swiper-prev')
      }

      if (el.hasAttribute('data-spacebetween')) {
        options.spaceBetween = 15
        options.breakpoints[1280].spaceBetween = parseInt(
          el.getAttribute('data-spacebetween')
        )
      }

      if (el.hasAttribute('data-slidestoshow')) {
        options.breakpoints = {
          992: {},
          1199: {}
        }
        options.breakpoints[992].slidesPerView = parseInt(
          el.getAttribute('data-slidestoshow')
        )
        options.breakpoints[1199].slidesPerView = parseInt(
          el.getAttribute('data-slidestoshow')
        )
      }

      // init slider
      new Swiper(el, options)
    })

    // Phone Concatenation Script For Tracking
    setTimeout(function () {
      $('.phone-text em').each(function () {
        var unsliced = $(this).text()
        var sliced = unsliced.slice(0, -2) + '...'
        $(this).text(sliced)
        var linked = 'tel:' + unsliced.replace(/\s/g, '')
        $(this).click(function () {
          if ($(window).width() < 1000) {
            window.location.href = linked
          } else {
            $(this).text(unsliced)
          }
        })
      })
    }, 3000)

    if ($('#timer').length) {
      var timeduration = $('.announcements').data('duration')
      var timetype = $('.announcements').data('type')
      startTimer(timeduration, timetype, $('#timer')[0])
    }
  })

  function initForm () {
    var form = document.getElementById('offer-form')

    if (!form) return

    $(form).validate({
      submitHandler: async function (form, event) {
        event.preventDefault()

        var btn = $(form).find('[type="submit"]')
        btn.disabled = true
        btn.html('Sending...')

        var data = new FormData(event.target)
        fetch(event.target.action, {
          method: form.method,
          body: data,
          headers: {
            Accept: 'application/json'
          }
        })
          .then(response => {
            /* if (typeof window.dataLayer != 'undefined') {
              window.dataLayer.push({
                event: 'gtm.formSubmission',
                form: form
              })
            } */
            window.location.href = window.location.href + '/thank-you'
          })
          .catch(error => {
            form.html(
              '<p class="error">There was an error sending your message. Please try again later.</p>'
            )
          })
      }
    })
  }

  // Sticky Header
  $(window).on('scroll load', function () {
    if ($(window).scrollTop() >= 50) {
      $('header').addClass('scrolled')
    } else {
      $('header').removeClass('scrolled')
    }
  })

  // Smooth Scroll To Anchor
  $(document).on('click', '.js-cta', function () {
    target = $(this).attr('data-target')

    if (target.length) {
      $('html, body').animate(
        {
          scrollTop: $(target)
            .find('form')
            .offset().top
        },
        1500
      )
    }
  })
})(jQuery)
